<template>
  <div>
    {{ apDate|capitalize }} {{ timeSlot }}
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex';
export default {
    props: {
        object: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            slots: 'events/timeSlots'
        }),
        timeSlot(){
            let s = this.slots.find(item => item.uid === this.object.timeslot)
            if(s) return s.startTime
            return '-'
        },
        apDate(){
            moment.locale('fr')
            return moment(this.object.date).format('dddd DD-MM-YYYY')
        }
    }

}
</script>